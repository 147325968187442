<template>
  <div>
    <b-row class="mt-4 d-flex flex-column">
      <b-col md="6">
        <b-skeleton type="input" />
      </b-col>
      <b-col
        md="12"
        class="mt-2"
      >
        <b-skeleton class="textarea-skeleton" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BSkeleton } from 'bootstrap-vue'

export default {
  name: 'GeneralSkeleton',
  components: {
    BRow,
    BCol,
    BSkeleton,
  },
}
</script>

<style scoped>
.textarea-skeleton {
  width: 100%;
  height: 200px;
}
</style>
