import { CHECKBOX_LEF } from '@/views/components/DynamicForm/constants'

export default function config() {
  const assetGeneralFields = {
    create_new_service_order_on_damage_report: {
      type: CHECKBOX_LEF,
      label: 'Create New Service Order on Damage Report',
      rules: 'required',
    },
  }
  return {
    assetGeneralFields,
  }
}
