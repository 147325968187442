<template>
  <div>
    <asset-general-form
      v-if="!loading"
      ref="asset-general-form"
    />
    <div v-else-if="loading">
      <general-skeleton />
    </div>
  </div>

</template>
<script>
import {
} from 'bootstrap-vue'
import GeneralSkeleton from '@/views/settings/inventory/general/components/GeneralSkeleton.vue'
import config from '../assetGeneralConfig'
import AssetGeneralForm from '../components/AssetGeneralForm.vue'

export default {
  name: 'AssetGeneralUpdate',
  components: {
    GeneralSkeleton,
    AssetGeneralForm,
  },
  data() {
    return {
      error: false,
      loading: false,
    }
  },
  computed: {
    assetGeneral() {
      return this.$store.state[this.MODULE_NAME].assetGeneralForm
    },
  },
  created() {
    this.loading = true
    this.$store.dispatch(`${this.MODULE_NAME}/getAssetGeneral`).then(res => {
      const { data } = res
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, data)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_ASSET_GENERAL_FORM_CLONE`, data)
    }).catch(err => {
      console.log(err.response?.data)
    }).finally(() => {
      this.loading = false
    })
  },
  setup() {
    const MODULE_NAME = 'asset-general'
    const MODULE_NAME_CLONE = 'cloneData'
    const { assetGeneralFields } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      assetGeneralFields,
    }
  },
}
</script>
