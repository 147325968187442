<template>
  <div v-if="!loading">
    <b-row>
      <b-col md="4">
        <div class="d-flex justify-content-start">
          <h1 class="font-weight-bolder font-medium-4">
            Asset Damage
          </h1>
        </div>
      </b-col>
      <b-col md="8">
        <div class="d-flex justify-content-end">
          <feather-icon
            v-if="organizationRequiresEditConfirmation"
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Edit')"
            :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
            size="36"
            class="ml-1 cursor-pointer"
            @click="isEditAble()"
          />
        </div>
      </b-col>
    </b-row>
    <error-alert
      :fields="assetGeneralFields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="assetGeneralsCreateVal"
      >
        <b-row>
          <b-col
            v-for="field in ['create_new_service_order_on_damage_report' ]"
            :key="field"
            class="mt-1"
            md="12"
          >
            <component
              :is="assetGeneralFields[field].type"
              v-model="assetGeneral[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="cancel"
              >
                {{ $t('Back to List') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import { required } from '@/libs/validations/validations'
import { scrollToError } from '@core/utils/utils'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BRow, BCol, BForm, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import store from '@/store'
import config from '../assetGeneralConfig'

export default {
  name: 'AssetGeneralForm',
  components: {
    BButton,
    BRow,
    BCol,
    BForm,
    ErrorAlert,
    ValidationObserver,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,

  },

  data() {
    return {
      required,
      error: {},
      loading: false,
      onSubmit: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    assetGeneral() {
      return this.$store.state[this.MODULE_NAME].assetGeneralForm
    },
    assetGeneralClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].assetGeneralFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.assetGeneral) !== JSON.stringify(this.assetGeneralClone)
    },
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.assetGeneralsCreateVal.validate()
        .then(success => {
          if (success) {
            const request = this.assetGeneral
            const defaultAssetGeneral = this.mappingFields(Object.keys(this.assetGeneralFields), request)
            this.sendNotification(this, { ...defaultAssetGeneral }, `${this.MODULE_NAME}/updateAssetGeneral`)
              .then(resp => {
                const { data } = resp
                this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, data)
                this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_ASSET_GENERAL_FORM_CLONE`, data)
              })
              .catch(err => {
                this.error = err.response?.data
              }).finally(() => {
                this.onSubmit = false
              })
          } else {
            scrollToError(this, this.$refs.assetGeneralsCreateVal)
          }
        })
    },
    clear() {
      this.$refs.assetGeneralsCreateVal.reset()
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
    },
    cancel() {
      // this.clear()
      this.$router.push({ name: 'settings' })
    },
    loader() {
      this.$refs.assetGeneralsCreateVal.reset()
      // const smth = JSON.parse(this.assetGeneralClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, this.assetGeneralClone)
    },
    getProps(fieldName) {
      const dataOF = {
        field: {
          ...this.assetGeneralFields[fieldName],
        },
        name: fieldName,
      }
      return dataOF
    },
  },
  setup() {
    const MODULE_NAME = 'asset-general'
    const MODULE_NAME_CLONE = 'cloneData'
    const { assetGeneralFields } = config()

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      assetGeneralFields,
    }
  },
}
</script>
